import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from ".././components/common/Loader";
import { getStudioGames } from "../service/actions/gameActions";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import UserList from "./UserList";
import { clearUserDeviceA, getStudioUsers, updateUser } from "../service/actions/usersActions";
import http from "../utils/Api";
import { markAsSus, unMarkAsSus} from "../service/actions/usersActions";
import {  updateUsers } from "../service/actionTypes/types";
import AmbassadorList from "./AmbassadorList";
import { getAmbassadors } from "../service/actions/AmbassadorAction";


const Ambassador = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const dispatch = useDispatch();
  const ambassador = useSelector((state) => state.ambassador);
  const [MAU, setMAU] = useState([])
  const [DAU, setDAU] = useState([])
  const [userType , setUserType] = useState('normal')
  const [currentPageMAU, setCurrentPageMAU] = useState(1);
  const [currentPageDAU, setCurrentPageDAU] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    dispatch(getAmbassadors());
  }, []);



  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const onToggleState = (user) => {
    dispatch(updateUser(user));
  };
  const handleClearDevice = (user) => {
    dispatch(clearUserDeviceA(user));
  }

  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
            }`}
        >
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Ambassador</h1>
            </Breadcrumb>
          </div>
          <div
            className="max-width-container"
            style={{ width: "100%", maxWidth: "90%", margin: "0 auto" }}
          >
            <div >
              <Row> 
                <Col md={12} >
                  <AmbassadorList  onToggleState={onToggleState}
                   userType={userType}
                   setUserType={setUserType}
                  //  onDeleteUser={handleDeleteUser}
                   />
                   
                </Col>
              </Row>
            </div>
          </div>
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Ambassador;
