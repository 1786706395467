import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Web3 from 'web3';
import USDCContractABI from  './UsdcContract'
import EarnContractABI from './EarnContract'
import NewEarnContractABI from './EarnContract3New'
// import ApprovalContractABI from './approveContract'
// import { refreshToken } from "../service/actions/authActions";
// import { useDispatch } from "react-redux";

const http = axios.create({
  // baseURL: "http://18.189.239.182:5000",
  // baseURL: "http://192.168.100.110:5000",
  // baseURL: 'http://192.168.18.50:4000',
  // baseURL: "http://localhost:5000/",
  // baseURL: "https://api.1lab.network/",
  // baseURL:" https://production.earnscape.io/",
   baseURL: " https://staging.earnscape.io/"
  //  baseURL: "http://localhost:4000/"
});






// Initialize react-toastify
toast.configure();

http.defaults.headers.post["Content-Type"] = "application/json";
http.defaults.headers.get["Content-Type"] = "application/json";
// http.interceptors.response.use(
//   async (response) => {
//     if (response.status >= 200 && response.status < 300) {
//       const message = response.data.message;
//       if (message) {
//         showToast(message);
//       }
//       return response.data;
//     }
//   },
//   async (error) => {
//     const { response, request } = error;

//     console.log("error", error);
//     if (response) {
//       if (response.status >= 400 && response.status <= 500) {
//         // alert(response.data?.message, 'error');

//           showToast(response.data?.message, "error");
//         return null;
//       }
//     } else if (request) {
//       showToast("Request failed. Please try again.", "error");
//       return null;
//     }
//     return Promise.reject(error);
//   }
// );


// Interceptor to refresh token if it has expired
http.interceptors.response.use(
  async (response) => {
    // logoutUser();
    // Check if response is successful
    if (response.status >= 200 && response.status < 300) {
      const message = response.data.message;
      if (message) {
        showToast(message);
      }
      return response.data;
    }
  },
  async (error) => {
    const { response, config } = error;

    if (response) {
      if (response.status === 401 && !config.__isRetryRequest) {
        try {
          console.log('401 IN UNug');
          const newToken = refreshToken1(localStorage.getItem("refreshToken"));

          if (newToken && newToken.data && newToken.data.accessToken) {
            const newAccessToken = newToken.data.accessToken;
            config.headers['accessToken'] = newAccessToken;
            config.__isRetryRequest = true;
            return http(config);
          }
        } catch (refreshError) {
          if(refreshError.response && refreshError.response.status === 403){
            
          }
          showToast("Failed to refresh access token. Please log in again.", "error");
          logoutUser();


          return Promise.reject(error);
        }
      } else if (response.status >= 400 && response.status <= 500) {
        showToast(response.data?.message, "error");
        // logoutUser();
      }
    } else {
      showToast("Request failed. Please try again.", "error");
    }
    return Promise.reject(error);
  }
);


const refreshToken1 = async (refreshToken) => {
  console.log("refreshToken", refreshToken);
  try {
    const res = await http.post("/api/admin/adminRefreshToken", {
      refreshToken: refreshToken,
    });
    if (res && res.data && res.data.accessToken) {
      const newAccessToken = res.data.accessToken;
      
      localStorage.setItem("accessToken", newAccessToken);
    }
    return res;
  } catch (err) {
    console.error(err);
  }
};


const logoutUser= ()=>{
  localStorage.removeItem("accessToken");
  localStorage.removeItem("sessionToken");
    
}

http.generateConfig = (accessToken) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      "Content-Type": "application/json",
      "access-token": token ? token : accessToken,
    },
  };
};


const showToast = (message, type = "success") => {
  if (message) {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

// const web3 = new Web3(
//   new Web3.providers.HttpProvider(
//     'https://restless-frosty-darkness.bsc-testnet.discover.quiknode.pro/436a9a80ab15ad829b5cb82d5afce7c38c3b9d5f/'
//   )
// )
// const usdContractAddress = '0x00a40b0Ad0Eab17aCc57dDd53c3760280523145a';
// const usContractInstance = new web3.eth.Contract(usdContractAbi, usdContractAddress);
// http.web3=web3
// http.usdContractAddress=usdContractAddress
// http.usdContractInstance = usContractInstance
// const contractAddress1 = '0x00a40b0Ad0Eab17aCc57dDd53c3760280523145a'
// const contractInstance1 = new web3.eth.Contract(contractAbi1, contractAddress1)


const USDC = new Web3(
  new Web3.providers.HttpProvider(
    'https://rpc-amoy.polygon.technology/'
  )
)

const Earn = new Web3(
  new Web3.providers.HttpProvider(
    'https://rpc.xinfin.network'
  )
)

const NewEarn = new Web3(
  new Web3.providers.HttpProvider(
    'https://rpc.xinfin.network'
  )
)

const usdcContactAddress ='0x095e7A76BeacBBDC80a3BD52bfcaf2d681fe0b22';
const usdcContactInstance = new USDC.eth.Contract(USDCContractABI,usdcContactAddress);


// const approvalContractAddress = '0xC8A087Ac4BAb015261DfC3469201F1169b8a5E00'
// const approvalContractInstance = new USDC.eth.Contract(ApprovalContractABI,approvalContractAddress)


const earnContractAddress = '0x636c958df6e5a721a1edcc60f7954fb671e8897c';
const earnContractInstance = new Earn.eth.Contract(EarnContractABI, earnContractAddress);


const newEarnContractAddress ='0x813f42c8f0cf12bdcb0e145e9541ed7b0dc8174a'
const newEarnContractInstance = new Earn.eth.Contract(NewEarnContractABI, newEarnContractAddress);


http.USDC= USDC;
http.usdcContactAddress= usdcContactAddress;
http.usdcContactInstance = usdcContactInstance;


// http.approvalContractAddress= approvalContractAddress;
// http.approvalContractInstance = approvalContractInstance;

http.Earn= Earn;
http.earnContractAddress= earnContractAddress;
http.earnContractInstance= earnContractInstance;



http.NewEarn= NewEarn;
http.newEarnContractAddress= newEarnContractAddress;
http.newEarnContractInstance= newEarnContractInstance;



export default http;




