export const add = "addGame";
export const get = "getGame";
export const getAll = "getStudioGames";
export const update = "updateGame";
export const remove = "removeGame";
export const mark = "markasfeatured";
export const unmark = "unmarkasfeatured";
export const sus = "markPlayerAsSus"
export const notSuspicious = "unMarkPlayerAsSus"

export const addUsers = "addUser";
export const getUser = "getUser";
export const getAllUsers = "getAllUsers";
export const getAllUsersPermission = "getAllUsersPermission";
export const updateUsers = "updateUser";
export const removeUser = "removeuser";
export const clearUserDevice ="clearUserDevice"
export const deleteUserAccount = "deleteUserAccount"

export const getSingleUserById = "getSingleUserById";

export const getGameStudios = "getGameStudio";
export const updateGameStudios = "updateGameStudio";

export const loginType = "login";
export const registerType = "register";


export const addReward = "addReward";
export const getReward = "getReward";
export const getAllRewards = "getStudioRewards";
export const updateReward = "updateReward";
export const removeReward = "removeReward";

export const getAllKYC ='getAllKYC'


// get Ambassador
export const getAllAmbassadors='getAllAmbassadors';
export const addAmbassador= 'addAmbassador';
export const getAmbassador= 'getAmbassador';
export const updateAmbassador='updateAmbassador';
export const deleteAmbassador ='removeAmbassador';


// get Ambasaddor Task
export const GET_ALL_AMBASSADORS_TASK='getAllAmbassadorsTask';
export const ADD_AMBASSADOR_TASK= 'addAmbassadorTask';
export const UPDATE_AMBASSADOR_TASK='updateAmbassadorTask';
export const DELETE_AMBASSADOR_TASK ='removeAmbassadorTask';
