import http from "../../utils/Api";
import {
    GET_ALL_AMBASSADORS_TASK,
    DELETE_AMBASSADOR_TASK,
    ADD_AMBASSADOR_TASK,
    UPDATE_AMBASSADOR_TASK,
} from "../actionTypes/types";
import { toast } from "react-toastify";

// Get all ambassadors
const getAmbassadorsTasks = () => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        const res = await http.get(`/api/admin/getAmbassadorTasks`, http.generateConfig(token));

        console.log(res,'the response data here')
        if (res) {
            dispatch({
                type: GET_ALL_AMBASSADORS_TASK,
                payload: res.data,
            });
        }
    } catch (error) {
        console.error("Error fetching ambassadors:", error);
    }
};

// Remove an ambassador
const removeAmbassadorTask = (id) => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        const res = await http.post(`/api/admin/removeAmbassador`, { userId: id }, http.generateConfig(token));

        if (res) {
            dispatch({
                type: DELETE_AMBASSADOR_TASK,
                payload: res.data,
            });
        }
    } catch (error) {
        console.error("Error removing ambassador:", error);
    }
};

// Add/Mark an ambassador
const addAmbassadorTask = (data) => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        const res = await http.post(`/api/admin/createAmbassadorTaskFromPost`, data, http.generateConfig(token));

        if (res) {
            toast.success("Task added successfully!");

            dispatch({
                type: ADD_AMBASSADOR_TASK,
                payload: res.data,
            });
        }
    } catch (error) {
        console.error("Error adding ambassador:", error);
    }
};

// Update ambassador details
const updateAmbassadorTask = (id, updatedData) => async (dispatch) => {
    try {
        const token = localStorage.getItem("accessToken");
        const res = await http.put(
            `/api/admin/updateAmbassador/${id}`,
            updatedData,
            http.generateConfig(token)
        );

        if (res) {
            dispatch({
                type: UPDATE_AMBASSADOR_TASK,
                payload: res.data,
            });
        }
    } catch (error) {
        console.error("Error updating ambassador:", error);
    }
};

export {
    getAmbassadorsTasks,
    removeAmbassadorTask,
    addAmbassadorTask,
    updateAmbassadorTask,
};
